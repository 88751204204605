import { useClerk } from "@clerk/clerk-react";
import React from "react";
import { Button, Center, Title, Stack } from "@mantine/core";

const Splash = () => {
  const { redirectToSignIn } = useClerk();

  return (
    <Center pt={{ base: "50", md: "200" }}>
      <Stack align="center">
        <Title>Hubflow</Title>
        <Button onClick={() => redirectToSignIn()}>Sign In</Button>
      </Stack>
    </Center>
  );
};

export default Splash;
