import { OrganizationSwitcher, SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { SideMenu } from "./navbar/side-menu";
import useUserType from "../utils/use-user-type";
import iconLogo from "./icon-logo.png";
import { Stack, Image, Navbar, Container, Nav, NavbarCollapse, Collapse, Offcanvas, Col, Button, Row } from "react-bootstrap";
import { PropsWithChildren, useState } from "react";
import { useTheme } from "./theme-context";
const Shell = ({ children }: PropsWithChildren) => {
  const userType = useUserType();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const handleToggleSideMenu = () => setShowSideMenu(!showSideMenu);
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    const newThem = theme === 'dark' ? 'light' : 'dark';
    setTheme(newThem);
    localStorage.setItem('theme', newThem);
  };
  
  return (
    <>
      <Navbar className="border-bottom bg-body position-fixed w-100" style={{zIndex: 4}}>
        <Container fluid>
          <Stack direction="horizontal" gap={3}>
            <div className="border rounded py-1 px-2 d-md-none" onClick={handleToggleSideMenu}>
              <i className="bi bi-list"></i>
            </div>
            <Navbar.Brand>
              <Image width={32} src={iconLogo} />
            </Navbar.Brand>
          </Stack>
          <Stack direction="horizontal" gap={3} className="align-items-start pt-2">
            <div style={{width: '34px'}} className="border rounded-circle p-1 text-center"
              role="button" onClick={toggleTheme}>
              <i className={`ms-auto bi bi-${theme === 'dark' ? 'moon-stars' : 'sun'}`}></i>
            </div>
            <UserButton />
            <OrganizationSwitcher hidePersonal={true} />
          </Stack>
        </Container>
      </Navbar>

      <Stack direction="horizontal" className="align-items-start">
        <Col xs={12} md={3}
          className={`p-3 bg-body position-fixed h-100 top-56 start-0 border-end ${
            showSideMenu ? 'd-block' : 'd-none d-md-block'
          }`}
          style={{ width: '15.625rem', height: 'calc(100vh - 56px)', zIndex: 4, marginTop: '62.5px'}}>
          <SideMenu />
        </Col>
        <Stack direction="horizontal" className="w-100" style={{marginTop: '62.5px'}}>
          <div><div className="d-none d-md-block" style={{ width: '15.625rem' }}></div></div>
          <Container fluid className="pt-3 w-100">
            {children}
          </Container>
        </Stack>
      </Stack>
    </>
  );
};

export default Shell;
