import { Shipment } from "../shipments/types";
import { TaskUpdate } from "../task-updates/types";

export enum TaskStatus {
  SUGGESTED = "suggested",
  IN_PROGRESS = "in_progress",
  ESCALATED = "escalated",
  AWAITING_SYNC = "awaiting_sync",
  COMPLETED = "completed",
  CLOSED = "closed_outside_hubflow",
  NO_ACTION = "no_action"
};

export const DISABLED_STATUES = [TaskStatus.NO_ACTION, TaskStatus.IN_PROGRESS, TaskStatus.COMPLETED, TaskStatus.CLOSED];

export enum TaskType {
  SCHEDULING = "scheduling",
  EMAIL = "email",
  PRESET = "preset",
};

export enum StopType {
  PICK_UP = "pick_up",
  DROP_OFF = "drop_off",
};

export enum ContactType {
  EMAIL = "email",
  PHONE = "phone",
  PORTAL = "portal",
};

export interface Task {
  id?: string;
  shipmentId: string;
  createdAt?: Date;
  status: TaskStatus;
  taskType: TaskType;
  stopType: StopType;
  scheduledAt: Date;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  timezone: string;
  locationName: string;
  locationNotes: string;
  confirmNum: string;
  poNum: string[];
  equipmentType: string;
  shipment: Shipment;
  schedulingMethod: ContactType;
  contacts: Contact[];
  taskUpdates: TaskUpdate[];
};

export interface Contact {
  id?: string;
  taskId: string;
  contact: string;
  contactType: ContactType;
};