import {
  CreateOrganization,
  OrganizationProfile,
  OrganizationSwitcher,
  OrganizationList,
  useUser,
  useOrganizationList,
  useOrganization,
} from "@clerk/clerk-react";
import OrganizationsCurrentShow from "../current-show";

const OrganizationSettings = () => {
  const { organization } = useOrganization();
  console.log(organization);
  return (
    <>{organization ? <OrganizationProfile /> : <OrganizationSwitcher />}</>
  );
};

export default OrganizationSettings;
