import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Popover, Spinner, Table } from "react-bootstrap";
import style from "./facilities.module.css";
import { useNavigate } from "react-router-dom"
import { PagingMeta } from "@/components/layout/paging";
import { Facility } from "../type";

const Search = () => {
  const { getToken, userId, isLoaded } = useAuth();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [meta, setMeta] = useState<PagingMeta>({ page: 1, perPage: 10, totalCount: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  
  const fetchFacilities = async (pgMeta: PagingMeta) => {
    setLoading(true);
    const accessToken = await getToken();
    let path = search?.length > 0 ? `search=${search}&` : '';
    const response = await fetch(
      `/api/v1/facilities?${path}page=${pgMeta.page}&per_page=${pgMeta.perPage}${
        pgMeta.sortBy ? `&sort_by=${pgMeta.sortBy}&sort=${pgMeta.sort}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const json = await response.json();
    const resStatus = response.status;
    if ([200, 304].includes(resStatus)) {
      setFacilities(json.items.map(f => ({ ...f, createdAt: new Date(f.createdAt) })));
      setMeta(json.meta);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/")
    }
    fetchFacilities(meta); 
  }, [search]);

  const popoverList = (items) => (
    <Popover id="popover-basic" className={style.popover_lg}>
      <Popover.Body>
        <ul className="mb-0 ps-3">
          {items.map(item => (<li>{item}</li>))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  const sort = (sortBy) => fetchFacilities({ ...meta,
    sortBy: sortBy, sort: meta.sort === 'asc' ? 'desc' : 'asc' });

  return (
    <>
      <h1 className="h2 text-capitalize">Facilities</h1>
      <div className="py-2">
        <Form.Control type="text" placeholder="Facility ID, name, or address" value={search}
          onChange={(e) => setSearch(e.currentTarget.value)} />
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : facilities.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>
                <span className={style.sort} role="button" onClick={() => sort('name')}>
                  Name{meta.sortBy === 'id' ? <i className={`ms-2 bi bi-caret-${meta.sort === 'asc' ? 'up' : 'down'}-fill`}></i> : <></>}
                </span>
              </th>
              <th>Address</th>
              <th>Most recent shipment</th>
              <th>Date</th>
              <th>Hours</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {facilities.map((facility) => (
              <tr key={facility.id} className="align-middle">
                <td>
                  <a href={`/facilities/${facility.id}`}>{facility.name}</a>
                </td>
                <td>
                  {facility.address}<br></br>
                  {facility.city}, {facility.state}, {facility.zipCode}
                </td>
                <td>
                  {facility.tasks.length ? <a href={`/tasks/${facility.tasks[0].id}`}>{facility.tasks[0].shipment.shipmentId}</a> : <></>}
                </td>
                <td>
                  {facility.createdAt.toLocaleDateString('us')}
                </td>
                <td>{facility.hours}</td>
                <td>
                  <OverlayTrigger trigger="click" rootClose={true} placement="auto"
                    overlay={popoverList(JSON.parse(facility.facilityNotes?.[0]?.notes || '[]').flat().filter(note => note))}>
                    <i role="button" title="Location Notes" className="bi bi-card-list h4"></i>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-center">
          <p>Type an ID above to perform a search.</p>
        </div>
      )}
    </>
  );
};

export default Search;