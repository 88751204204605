import { createContext, useContext, useState, ReactNode } from 'react';

export type Theme = 'dark' | 'light' ;

const ThemeContext = createContext({
  theme: 'light' as Theme,
  setTheme: (theme: Theme) => {}
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<Theme>('light');

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);