import { useAuth } from "@clerk/clerk-react";
import {
  Button,
  Modal,
  Group,
  Input,
  Stack,
  Textarea,
  Title,
  JsonInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import React from "react";
const WorkflowBuilder = () => {
  const { getToken } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [workflow, setWorkflow] = React.useState({
    result: "",
    title: "Walmart Appointment Scheduling",
    prompt: `
    An appointment is needed at a facility located at 0.000, 0.000.
    The truck is heading to 0.000, 0.000,
    which is 0 miles away.
    The truck is scheduled to arrive at the second facility at 12:00AM Jan 1st, 1970, ET.

    When should the pickup appointment be scheduled for at the first facility? Make sure this pickup appointment is before the dropoff appointment, and allow ample time for travel between locations.

    Please return the time in the format iso8601 in a json object where the key is 'suggested_pickup_time'. Assume that facilities are not open on the weekends, and adjust accordingly
`,
    sop: `
    Scheduling Checklist – Quick reference guide
    Purpose: Important factors to note when scheduling ANY shipment, and will contribute to less scheduling errors and more success when selling a shipment to carriers
  
  
    * Transit – does it make sense with current times and miles?
    * All PO's added – does McLeod match the customer portal? Is shipper/receiver aware of all PO’s?
    * Loading order – Is the loading order noted if there is more than one delivery? Is shipper aware?
    * Customer Escalation – Have dates changed from original tendered dates?
    * All stops scheduled – Did you check the STOPS tab in Order Entry?
    * KPI - Sched >> Ops User field to your name
    * Tracking Sheet updated (if applicable)
    * Add CALL IN in McLeod for EVERY step of scheduling or any changes
    Transit Time Tips
    * Anything under 500 miles should not be scheduled over a weekend unless customer really needs it, and Acct Mgr should be kept in the loop
    * Avoid very early pickups whenever possible unless Acct Mgr agrees otherwise (0300-0900)
    * Ideal pickup times are 1000-1600, provided that leaves enough time for transit and OTD
  
  
    0-120 miles (same day)
    * Can it deliver same day within receiving hours and 2-3 hours transit? Keep in mind 2 hours load time
    0-120 miles (next day)
    * Preferred pickup times 1500 or later, delivering earliest available following day (0400-0600 if able)
    121-450 miles (same day)
    * Typically reserved for nighttime receivers (1900-0400 for example)
    * Ideal pickup times are 0800-1300 in these cases as long as they can deliver straight through within receiving hours for that facility
    121-450 miles (next day)
    * Preferred pickup times 1000-1600, delivering 0600-1100 the next day. The more miles there are, the earlier pickup window should be targeted. (ex. 450 miles would be better off picking up at 1000 than 1600)
    451-550 miles (next day)
    * Preferred pickup times 0800-1100, delivering 0800-1100 the next day
    551-675 miles (next day)
    * Preferred pickup times 0800-1000, delivering 0800-1200 the next day
    * The closer we get to that 675 mile mark, the closer we are to reaching a solo driver’s limit on HOS, there is very little room for error here and early pickup and later delivery should be secured. (ex. 675 miles is very acceptable to have an 0800 pickup and a 1000-1200 delivery the next day to allow a little buffer time)
    * Any loading or transit delays will likely cause a missed delivery
    * We may even need to consider TEAM drivers, in which case it should be escalated to the account manager and/or customer for approval
    * When in doubt, please check with your manager or the account manager
    650-850 miles (2 day transit)
    * Preferred pickup times are 1100-2000 when this distance is over 2 days, delivering during standard morning receiving hours two days later
    851-1150 miles (2 day transit)
    * Preferred pickup times are 1000-1600, with the more miles there are, the earlier the pickup should be in this window, delivering during standard morning receiving hours two days later
    1151-1250 miles (2 day transit)
    * Preferred pickup times are 0800-1200, as this reaches the upper limit of 600+ miles per day for a driver, delivering during standard morning receiving hours two days later
    1251 – 1800 miles (3 day transit)
    1801 – 2400 miles (4 day transit)
    *From this point on after 1250 miles, add another day of transit for every additional 500-600 miles. The more days of transit we have, the more likely a driver will also need to take his 36 hour weekly reset as well*
  
    
    
    
    
    `,
  });

  const runWorkflow = async () => {
    setLoading(true);

    const accessToken = await getToken();
    const response = await fetch(`/api/v1/workflows/`, {
      method: "POST",
      body: JSON.stringify({ sop: workflow.sop, prompt: workflow.prompt }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    setLoading(false);
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      setWorkflow({ ...workflow, result: JSON.stringify(json, null, 2) });
    }
  };

  return (
    <>
      <Modal
        opened={workflow.result}
        onClose={() => {
          setWorkflow({ ...workflow, result: "" });
        }}
        title="Result"
      >
        <JsonInput autosize minRows={10} disabled value={workflow.result} />
      </Modal>
      <Stack>
        <Group justify="space-between">
          <Title>Workflow Builder</Title>
          <Button loading={loading} onClick={runWorkflow} color="green">
            Test
          </Button>
        </Group>
        <Input.Wrapper label="Title">
          <Input value={workflow.title} disabled />
        </Input.Wrapper>
        <Textarea
          autosize
          maxRows={12}
          resize="vertical"
          label="Standard Operating Procedures"
          description={"Describe in plain english how to complete the task."}
          value={workflow.sop}
          onChange={(e) =>
            setWorkflow({ ...workflow, sop: e.currentTarget.value })
          }
        />
        <Textarea
          autosize
          maxRows={12}
          resize="vertical"
          label="Prompt"
          value={workflow.prompt}
          onChange={(e) =>
            setWorkflow({ ...workflow, prompt: e.currentTarget.value })
          }
        />
      </Stack>
    </>
  );
};

export default WorkflowBuilder;
