import { getScheduledDateTime, setScheduledDateTime } from "@/components/utils/task-utils";
import useRailsToast from "@/components/utils/use-rails-toast";
import { useAuth } from "@clerk/clerk-react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { Task, TaskStatus } from "../types";

const AppointmentInput = ({ task, isDate, disabled, onUpdate, className } :
  { task: Task, isDate: boolean, disabled?: boolean, onUpdate?: () => void, className?: string }) => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const [taskTime, setTaskTime] = useState(getScheduledDateTime(task, isDate));

  if (task.status === TaskStatus.NO_ACTION)
    disabled = true;
  
  const handleKeyDown = (task, isDate, e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateTaskTime(task, isDate, e);
    }
  };

  const updateTaskTime = (task, isDate, e) => {
    const value = e.target.value;
    if (e.target.checkValidity()) {
      let newDate = setScheduledDateTime(task, value, isDate);
      if (task.scheduledAt.getTime() != newDate.getTime()) {
        task.scheduledAt = newDate;
        updateTask(task);
      }
      e.target.form.classList.remove('was-validated');
    } else {
      e.target.form.classList.add('was-validated');
    }
    return value;
  };

  const updateTask = async (task) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { id: task.id, scheduledAt: task.scheduledAt } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if ([200].includes(response.status)) {
      railsToast({ info: "Task has been updated!" }, 200);
      task.taskUpdates.push(...await response.json());
      onUpdate();
    }
    else
      railsToast(await response.json(), response.status);
    
  };

  return (
    <Form className={className}>
      <Form.Control type={isDate ? "date" : "time"} required disabled={disabled}
        min={`${new Date().getFullYear() - 20}-01-01`} style={isDate ? { width: '155px'} : null }
        max={`${new Date().getFullYear() + 50}-12-31`} value={taskTime}
        onChange={e => setTaskTime(e.target.value)}
        onBlur={e => updateTaskTime(task, isDate, e)}
        onKeyDown={e => handleKeyDown(task, isDate, e)} />
    </Form>
  )
}

export default AppointmentInput;