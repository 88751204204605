import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import Routes from "./layout/routes";
import { MantineProvider } from "@mantine/core";
import "@mantine/notifications/styles.css";
import { Notifications } from "@mantine/notifications";
import "@mantine/charts/styles.css";
import { Theme, ThemeProvider, useTheme } from './layout/theme-context';
const App = () => {
  const { theme, setTheme } = useTheme();
  
  useEffect(() => {
    const storeTheme = localStorage.getItem('theme');
    if (storeTheme) {
      setTheme(storeTheme as Theme);
    } else {
      setTheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-mantine-color-scheme', theme);
    document.documentElement.setAttribute('data-bs-theme', theme);
  }, [theme]);

  return (
    <StrictMode>
      <MantineProvider defaultColorScheme={theme}>
        <Notifications position="top-right" />
        <Routes />
      </MantineProvider>        
    </StrictMode>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const root = createRoot(document.getElementById("root"));
  root.render(<ThemeProvider><App /></ThemeProvider>);
});
