import { getStatusClass } from "@/components/tasks/show/task-status-pill";
import { TaskStatus } from "@/components/tasks/types";
import {
  IconArrowUpRight,
  IconArrowDownRight,
  IconCalendar,
  IconCalendarStats,
  IconCalendarCheck,
  IconAlertTriangle,
} from "@tabler/icons-react";
import { Card, Row, Stack } from "react-bootstrap";
import { ReportItem } from "../type";

const icons = { [TaskStatus.SUGGESTED]: IconCalendar,
  [TaskStatus.IN_PROGRESS]: IconCalendarStats,
  [TaskStatus.ESCALATED]: IconAlertTriangle,
  [TaskStatus.COMPLETED]: IconCalendarCheck };

export function StatsGrid({data} : {data: ReportItem[]}) {
  const stats = data.map((stat) => {
    const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;
    stat.icon = icons[stat.status];
    return (
      <div className="p-2">
        <Card>
          <Card.Body>
            <Card.Text>
              <Stack direction="horizontal" className="mb-4" >
                <span className={`text-uppercase fs-7 fw-bold text-${getStatusClass(stat.status)}`}>
                  {stat.status.replaceAll('_', ' ')}</span>
                <stat.icon size="1.4rem" stroke={2} className="ms-auto" />
              </Stack>
              <span className="fw-bold fs-5">{stat.value}</span>
              <span className={`ms-2 fs-7 text-${stat.diff > 0 ? 'success' : 'danger'}`}>{stat.diff}% <DiffIcon size="1rem" stroke={1.5} /></span>
              <div className="mt-2 fs-8 text-secondary">Compared to previous week</div>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  });
  return <Row xs={1} md={2} xl={4} className="mx-2">{stats}</Row>;
}
