import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ContactType, Task, TaskStatus } from "../types";
import TaskUpdates from "./task-updates";
import TaskStatusPill from "./task-status-pill";
import CreateTaskUpdate from "./task-updates/create-task-update";
import { Button, Form, Modal, Spinner, Stack } from "react-bootstrap";
import ContactsInput from "./contacts/contacts_input";
import AppointmentInput from "./appointment_input";
import TaskTypePill from "./task-type-pill";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { timezones } from "@/components/utils/task-utils";

const TaskShow = () => {
  const { getToken } = useAuth();
  const { taskId } = useParams();
  const railsToast = useRailsToast();
  const [task, setTask] = useState<Task>(null);
  const [confirmNum, setConfirmNum] = useState<string>(null);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [updateKey, setUpdateKey] = useState<number>(0);

  const fetchTask = async () => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${taskId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      });
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setTask({...json, createdAt: new Date(json.createdAt), scheduledAt: new Date(json.scheduledAt),
        taskUpdates: json.taskUpdates.map(tu => ({...tu, createdAt: new Date(tu.createdAt) }))});
    } else {
      railsToast({ info: json.error }, status);
    }  
    setLoading(false);
  };

  const syncAppointmentTime = async () => {
    if (task.shipment.uatId) {
      const accessToken = await getToken();
      const response = await fetch(`/api/v1/tasks/${task.id}/sync_appointment_time`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          }
        });
      const json = await response.json();
      const status = response.status;
      if ([200, 304].includes(status)) {
        railsToast({ info: "Appointment time has been updated on UAT!" }, status);
        task.status = TaskStatus.COMPLETED;
        task.taskUpdates.push(...json);
        taskUpdated();
      } else {
        railsToast({ info: json.error }, status);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTask();
  }, []);

  useEffect(() => {
    setConfirmNum(task?.confirmNum);
  }, [task]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateConfirmNum();
    }
  };

  const updateConfirmNum = () => {
    if (confirmNum != task.confirmNum) {
      updateTask({ confirmNum: confirmNum }, 
        'Appointment confirmation number has been updated!',
        () => task.confirmNum = confirmNum);
    }
  };

  const updateSchedulingMethod = (schedulingMethod: ContactType) => {
    updateTask({ schedulingMethod: schedulingMethod }, 
      'Task scheduling method has been updated!',
      () => task.schedulingMethod = schedulingMethod)
  };

  const updateTask = async (taskChanges, message,
    callBack: () => void) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: taskChanges }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      railsToast({ info: message }, status);
      callBack();
      task.taskUpdates.push(...json);
      taskUpdated();
    } else {
      railsToast(json, status);
    }
  };


  const taskUpdated = () => setUpdateKey(updateKey+1);

  if (loading) {
    return (
    <div className="text-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    );
  }
  if (task)
  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <Stack direction="horizontal" gap={2}>
          <h1 className="h2">Task: Stop {task.shipment.tasks.findIndex(t => t.id === task.id) + 1}</h1>
          <TaskTypePill taskType={task.taskType} />
        </Stack>
        <Stack direction="horizontal" gap={2}>
          {[TaskStatus.ESCALATED, TaskStatus.AWAITING_SYNC].includes(task.status) && task.shipment.uatId ?
            <Button onClick={syncAppointmentTime} variant="outline-secondary">Sync</Button> : <></>}
          <Button onClick={() => setShowNotes(true)} variant="outline-secondary">View notes</Button>
        </Stack>
      </Stack>
      <div className="border-bottom my-3"></div>
      <div>Information</div>
      <Stack direction="horizontal" className="align-items-start">
        <Stack className="w-50">
          <Stack direction="horizontal" gap={2}>
            <span className="fw-bold">Shipment:</span>
            <div>{task.shipment.shipmentId}</div>
          </Stack>
          <Stack direction="horizontal" className="align-items-start" gap={2}>
            <div className="fw-bold">PO Number:</div>
            <div>{task.poNum.map(po => <div>{po}</div>)}</div>
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <div className="fw-bold">Confirmation Number:</div>
            <Form.Control type="text" value={confirmNum} className="me-2"
              onChange={e => setConfirmNum(e.target.value)}
              onKeyDown={handleKeyDown} onBlur={updateConfirmNum} style={{maxWidth: '200px'}}/>
          </Stack>
        </Stack>
        <Stack className="w-50">
          {task.shipment.uatId ? <Stack direction="horizontal" className="align-items-start" gap={2}>
            <div className="fw-bold">UAT Order ID:</div>
            <div>{task.shipment.uatId}</div>
          </Stack> : <></>}
          <Stack direction="horizontal" className="align-items-start" gap={2}>
            <div className="fw-bold">Location:</div>
            <div>{task.locationName}</div>
          </Stack>
          <div className="mt-auto"><TaskStatusPill key={updateKey} status={task.status} className="mt-3" /></div>
        </Stack>
      </Stack>
      <div className="mt-3">Activity</div>
      <div className="mt-2">
        <Stack direction="horizontal" gap={2}>
          <span className="fw-bold">Scheduling Method:</span>
          <Form.Select value={task.schedulingMethod} style={{maxWidth: '200px'}}
            onChange={async (e) => await updateSchedulingMethod(e.target.value as ContactType)}>
            <option value={ContactType.EMAIL}>Email</option>
            <option value={ContactType.PHONE}>Phone</option>
            <option value={ContactType.PORTAL}>Portal</option>
          </Form.Select>
        </Stack>
      </div>
      <div className="mt-2">
        <Stack direction="horizontal" gap={2}>
          <span className="fw-bold">Contacts:</span>
          <ContactsInput key={updateKey} task={task} onUpdate={taskUpdated} />
        </Stack>
      </div>
      <div className="mt-2">
        <Stack direction="horizontal" gap={2}>
          <span className="fw-bold">Appointment Time:</span>
          <AppointmentInput task={task} isDate={true} onUpdate={taskUpdated} />
          <AppointmentInput task={task} isDate={false} onUpdate={taskUpdated} />
          <div className="py-2 px-1">{timezones[task.timezone]}</div>
        </Stack>
      </div>
      <div className="mt-5">
        <CreateTaskUpdate key={updateKey} task={task} onUpdate={taskUpdated} />
      </div>
      <div className="mt-2">
        <TaskUpdates key={updateKey} task={task} />
      </div>
      <Modal show={showNotes} onHide={() => setShowNotes(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Location notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {JSON.parse(task.locationNotes)?.flat()
              .filter(note => note).map(note => (<li>{note}</li>))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaskShow;
