export enum UpdateType {
  MESSAGE = "message",
  SCHEDULED_AT = "scheduled_at",
  STATUS = "status",
  CONTACTS = "contacts",
  SCHEDULING_METHOD = "scheduling_method",
  PLANNED_AT = "planned_at",
  SYNCED = "synced",
  CONFIRMATION_NUMBER = "confirmation_number"
};

export interface TriggerSource {
  name: string;
  address: string;
}

export interface TaskUpdate {
  value1: string;
  value2: string;
  createdAt?: Date;
  taskId: string;
  updateType: UpdateType;
  createdByName: string;
  triggeredBy: TriggerSource
}
