import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Popover, Spinner, Stack, Table } from "react-bootstrap";
import style from "./shipments.module.css";
import ContactsInput from "../../tasks/show/contacts/contacts_input";
import { ContactType, DISABLED_STATUES, Task, TaskStatus } from "@/components/tasks/types";
import TaskStatusPill from "@/components/tasks/show/task-status-pill";
import AppointmentInput from "@/components/tasks/show/appointment_input";
import { HumanizeType } from "@/components/tasks/show/humanize-types";
import useRailsToast from "@/components/utils/use-rails-toast";
import { useNavigate } from "react-router-dom";
import Paging, { PagingMeta } from "@/components/layout/paging";
import { timezones } from "@/components/utils/task-utils";

const Search = ({status} : {status?: TaskStatus}) => {
  const { getToken, userId, isLoaded } = useAuth();
  const railsToast = useRailsToast();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [meta, setMeta] = useState<PagingMeta>({ page: 1, perPage: 10, totalCount: 0 });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const navigate = useNavigate();
  
  const fetchShipments = async (pgMeta: PagingMeta) => {
    setLoading(true);
    const accessToken = await getToken();
    let path = id?.length > 0 ? `id=${id}&` : '';
    const response = await fetch(
      `/api/v1/shipments?${path}page=${pgMeta.page}&per_page=${pgMeta.perPage}${
        pgMeta.sortBy ? `&sort_by=${pgMeta.sortBy}&sort=${pgMeta.sort}` : ''}${
        status ? `&status=${status}` : ''}`,
      { 
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const json = await response.json();
    const resStatus = response.status;
    if ([200, 304].includes(resStatus)) {
      setTasks(json.items.map(sh => sh.tasks.map(tsk => ({...tsk, createdAt: new Date(tsk.createdAt),
        scheduledAt: new Date(tsk.scheduledAt), shipmentId: sh.id, shipment: sh }))).flat()?? []);
      setMeta(json.meta);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/")
    }
    fetchShipments(meta); 
  }, [id]);

  const popoverList = (items) => (
    <Popover id="popover-basic" className={style.popover_lg}>
      <Popover.Body>
        <ul className="mb-0 ps-3">
          {items.map(item => (<li>{item}</li>))}
        </ul>
      </Popover.Body>
    </Popover>
  );

  const sendEmails = async () => {
    setLoading(true);
    const accessToken = await getToken();
    let resStatus = 201;
    let json = null;
    for (let task of tasks.filter(tsk => !DISABLED_STATUES.includes(tsk.status) && selectedRows.includes(tsk.shipmentId))) {
      const response = await fetch(`/api/v1/tasks/${task.id}/send_email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      if (resStatus !== 201) {
        resStatus = response.status;
        json = await response.json();
        break;
      }
      else
        task.status = TaskStatus.IN_PROGRESS;
    }
    setLoading(false);
    setTasks([...tasks]);
    if ([201].includes(resStatus)) {
      railsToast({ info: "Email was sent!" }, resStatus);
    } else {
      railsToast(json, resStatus);
    }
  };

  const updateSchedulingMethod = async (task: Task, schedulingMethod: ContactType) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { schedulingMethod: schedulingMethod } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      railsToast({ info: "Task scheduling method has been updated!" }, status);
      task.schedulingMethod = schedulingMethod;
      setTasks([...tasks])
    } else {
      railsToast(json, status);
    }
  };

  const sort = (sortBy) => fetchShipments({ ...meta,
    sortBy: sortBy, sort: meta.sort === 'asc' ? 'desc' : 'asc' });

  return (
    <>
      <h1 className="h2 text-capitalize">{status?.replaceAll('_', ' ')} Shipments</h1>
      <div className="py-2">
        <Form.Control type="text" placeholder="Shipment ID" value={id}
          onChange={(e) => setId(e.currentTarget.value)} />
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : tasks.length > 0 ? (
        <>
          <Stack direction="horizontal" className="pt-2 justify-content-between align-items-start">
            {selectedRows.length ? <div>
              <span className="px-2">{selectedRows.length} shipments selected</span>
              <Button variant="outline-primary" onClick={sendEmails} disabled={loading}>
                Request appointments</Button>
            </div> : <div></div>}
            <Paging meta={meta} onPaging={fetchShipments}></Paging>
          </Stack>
          <Table>
            <thead>
              <tr>
                <th>
                  <Form.Check type="checkbox" aria-label="Select all"
                    checked={selectedRows.length === new Set(tasks.map(task => task.shipmentId)).size}
                    onChange={(event) => setSelectedRows(event.currentTarget.checked ?
                      Array.from(new Set(tasks.map(task => task.shipmentId))) : [])} />
                </th>
                <th>
                  <span className={style.sort} role="button" onClick={() => sort('shipment_id')}>
                    ID{meta.sortBy === 'id' ? <i className={`ms-2 bi bi-caret-${meta.sort === 'asc' ? 'up' : 'down'}-fill`}></i> : <></>}
                  </span>
                </th>
                <th>Distance</th>
                <th>
                  <span className={style.sort} role="button" onClick={() => sort('bol_num')}>
                    BOL{meta.sortBy === 'bol_num' ? <i className={`ms-2 bi bi-caret-${meta.sort === 'asc' ? 'up' : 'down'}-fill`}></i> : <></>}
                  </span>
                </th>
                <th>Route</th>
                <th>
                  <span className={style.sort} role="button" onClick={() => sort('scheduled_at')}>
                    Appointment Date
                    <span className="ms-3">Time</span>
                    {meta.sortBy === 'scheduled_at' ? <i className={`ms-2 bi bi-caret-${meta.sort === 'asc' ? 'up' : 'down'}-fill`}></i> : <></>}
                  </span>
                </th>
                <th>Status</th>
                <th colSpan={2}>Scheduling Method & Contacts</th>
                <th>PO number</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task) => (
                <tr key={task.id} className="align-middle">
                  { !task.shipment.tasks.findIndex(t => t.id === task.id) ? <>
                    <td rowSpan={task.shipment.tasks.length}>
                      <Form.Check type="checkbox" aria-label="Select the shipment"
                        checked={selectedRows.includes(task.shipmentId)}
                        onChange={(event) =>
                          setSelectedRows(
                            event.currentTarget.checked
                              ? [...selectedRows, task.shipmentId]
                              : selectedRows.filter((position) => position !== task.shipmentId)
                          )
                        }
                      />
                    </td>
                    <td rowSpan={task.shipment.tasks.length}>
                      <a href={`/shipments/${task.shipmentId}`}>{task.shipment.shipmentId}</a>
                      <br></br>
                      {task.shipment.uatId}
                    </td>
                    <td rowSpan={task.shipment.tasks.length}>{task.shipment.billDistance}</td>
                    <td rowSpan={task.shipment.tasks.length}>{task.shipment.bolNum}</td>
                  </> : <></> }
                  <td>
                    <a href={`/tasks/${task.id}`}>
                      <strong>
                        <HumanizeType type={task.stopType} /> #{task.shipment.tasks.findIndex(t => t.id === task.id) || 1}
                      </strong>
                    </a><br></br>
                    {task.city}, {task.state}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <AppointmentInput task={task} isDate={true} />
                      <AppointmentInput task={task} isDate={false} className="ms-2" />
                      <div className="py-2 px-1">{timezones[task.timezone]}</div>
                    </div>
                  </td>
                  <td><TaskStatusPill status={task.status} /></td>
                  <td>
                    <Form.Select value={task.schedulingMethod} style={{minWidth: '100px'}}
                      onChange={async (e) => await updateSchedulingMethod(task, e.target.value as ContactType)}>
                      <option value={ContactType.EMAIL}>Email</option>
                      <option value={ContactType.PHONE}>Phone</option>
                      <option value={ContactType.PORTAL}>Portal</option>
                    </Form.Select>
                  </td>
                  <td className="py-0">
                    <ContactsInput key={task.id} task={task} showAll={false} />
                  </td>
                  <td>
                    {task.poNum?.length < 3 ? task.poNum?.map(po => <div>{po}</div>) :<>
                      {task.poNum[0]}
                      
                      <OverlayTrigger trigger="click" rootClose={true} placement="auto"
                        overlay={popoverList(task.poNum)}>
                        <div role="button"><a className="primary">more...</a></div>
                      </OverlayTrigger>
                    </>}
                  </td>
                  <td>
                    <OverlayTrigger trigger="click" rootClose={true} placement="auto"
                      overlay={popoverList(JSON.parse(task.locationNotes).flat().filter(note => note))}>
                      <i role="button" title="Location Notes" className="bi bi-card-list h4"></i>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="text-center">
          <p>Type an ID above to perform a search.</p>
        </div>
      )}
    </>
  );
};

export default Search;
