import { memo, useEffect, useMemo, useState } from "react";
import { ButtonGroup, Dropdown, Pagination, Stack } from "react-bootstrap";

const Paging = ({meta, onPaging} : {meta: PagingMeta, onPaging: (meta: PagingMeta) => void}) => {
  const [pages, setPages] = useState<number[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const perPages = [10, 20, 30, 50];

  useEffect(() => {
    let totalPages = Math.ceil(meta.totalCount / meta.perPage);
    let firstPage = meta.page - 3;
    let lastPage = meta.page + 3;
    
    if (firstPage < 2)
      firstPage = 1;
  
    if (lastPage > totalPages - 1)
      lastPage = totalPages;
    setPages(Array.from({length: lastPage - firstPage + 1}, (_, i) => firstPage + i));
    setTotalPages(totalPages);
  }, [meta]);

  return (
    <Stack direction="horizontal" gap={2} className="align-items-start">
      <Pagination>
        <Pagination.First onClick={() => onPaging({...meta, page: 1})} disabled={meta.page === 1} />
        <Pagination.Prev onClick={() => onPaging({...meta, page: meta.page - 1})} disabled={meta.page === 1} />
        { meta.page > 4 ? <Pagination.Ellipsis disabled /> : <></> }
        {pages.map(pg => <Pagination.Item onClick={() => onPaging({...meta, page: pg})}
          active={pg === meta.page}>{pg}</Pagination.Item>)}
        { (totalPages - meta.page) > 4 ? <Pagination.Ellipsis disabled /> : <></> }
        <Pagination.Next onClick={() => onPaging({...meta, page: meta.page + 1})} disabled={meta.page === totalPages} />
        <Pagination.Last onClick={() => onPaging({...meta, page: totalPages})} disabled={meta.page === totalPages} />
      </Pagination>
      <Dropdown as={ButtonGroup} show={showDropdown}>
        <ul className="pagination" onClick={() => setShowDropdown(!showDropdown)}>
          <li className="page-item"><a className="page-link">{meta.perPage}</a></li>
          <li className="page-item"><Dropdown.Toggle as="a" className="page-link"></Dropdown.Toggle></li>
        </ul>
        <Dropdown.Menu show={showDropdown} className="dropdown-menu-right">
          {perPages.map(perPage =>
          <Dropdown.Item onClick={() => onPaging({...meta, page: 1, perPage: perPage})}>{perPage}</Dropdown.Item>)}
        </Dropdown.Menu>
      </Dropdown>
    </Stack>
  );
};

export interface PagingMeta {
  page: number;
  perPage: number;
  totalCount: number;
  sortBy?: string;
  sort?: string;
}

export default Paging