import { StatsGrid } from "./stat-grids";
import { LineChart } from "@mantine/charts";
import { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "../utils/use-rails-toast";
import { ReportItem } from "./type";
import { TaskStatus } from "../tasks/types";
import moment from "moment-timezone";

const Analytics = () => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<ReportItem[]>([]);
  const [chartData, setChartData] = useState([]);
  const [chart2Data, setChart2Data] = useState([]);
  const [chart3Data, setChart3Data] = useState([]);
  const [chart4Data, setChart4Data] = useState([]);
  const [chart5Data, setChart5Data] = useState([]);
  const [chart6Data, setChart6Data] = useState([]);

  useEffect(() => {
    const lastMonth = moment().subtract(30, 'days');
    setChart6Data(getDatesInRange(lastMonth, moment()).map(date => ({ date: date.format('YYYY-MM-DD') })) as []);
    setChart5Data(getDatesInRange(lastMonth, moment()).map(date => ({ date: date.format('YYYY-MM-DD') })) as []);
    setChart4Data(getDatesInRange(lastMonth, moment()).map(date => ({ date: date.format('YYYY-MM-DD') })) as []);
    setChart3Data(getDatesInRange(lastMonth, moment()).map(date => ({ date: date.format('YYYY-MM-DD') })) as []);
    setChart2Data(getDatesInRange(lastMonth, moment()).map(date => ({ date: date.format('YYYY-MM-DD') })) as []);
    setChartData(getDatesInRange(lastMonth, moment()).map(date => ({ date: date.format('YYYY-MM-DD') })) as []);
    fetchReports();
  }, []);

  useEffect(() => {
    if (loading && chartData.length) {
      setLoading(false);
      fetchIncomplete();
      fetchClosed();
      fetchEdited();
      fetchConfirmedCorrectly();
      fetchConfirmedDifferently();
      fetchAvgTime();
      fetchAvgStatusTime();
      fetchAvgReplyTime();
      fetchAppointmentTypes();
    }
  }, [chartData]);

  const fetchReports = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setData([TaskStatus.SUGGESTED, TaskStatus.IN_PROGRESS, TaskStatus.ESCALATED,
      TaskStatus.COMPLETED].map(status => {
        let today = json.today.find(item => item.status === status);
        let yesterday = json.yesterday.find(item => item.status === status);
        return {
          status: status, value: today?.count ?? 0,
          diff: Math.round(((((today?.count ?? 0) - (yesterday?.count ?? 0)) / (today?.count || 1)) || 0) * 100)
        };
      }) as ReportItem[]);
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const getDatesInRange = (startDate: moment, endDate: moment): moment[] => {
    const dates: moment[] = [];
    let currentDate = moment(startDate);
    while (currentDate <= endDate) {
      dates.push(moment(currentDate));
      currentDate.add(1, 'day');
    }
    return dates;
  }

  const fetchIncomplete = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/incomplete',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChartData(chartData.map(item => {
        for (const status of [TaskStatus.SUGGESTED, TaskStatus.IN_PROGRESS, TaskStatus.ESCALATED]) {
          item[status.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase())]
            = json.find(i => i.date === item.date && i.status === status)?.count || 0;
        }
        return item;
      }));
      fetchScheduled();
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchScheduled = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/scheduled',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChartData(chartData.map(item => ({...item,
        ['Completed']: json.find(i => i.date === item.date && i.status === TaskStatus.COMPLETED)?.count || 0 })));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchClosed = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/closed',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChartData(chartData.map(item => {
        item['Closed Outside HubFlow']
          = json.find(i => i.date === item.date && i.status === TaskStatus.CLOSED)?.count || 0;
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchEdited = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/edited',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChart2Data(chart2Data.map(item => {
        for (const edited of ['Contacts', 'Appointment Time', 'Escalated']) {
          item[edited] = json[edited].find(i => i.date === item.date)?.round || 0;
        }
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchConfirmedCorrectly = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/confirmed_correctly',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChart3Data(chart3Data.map(item => {
        item["Confirmed Correctly"] = json.find(i => i.date === item.date)?.synced || 0;
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchConfirmedDifferently = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/confirmed_differently',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChart3Data(chart3Data.map(item => {
        item["Confirmed Differently"] = json.find(i => i.date === item.date)?.synced || 0;
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchAvgTime = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/avg_time',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChart4Data(chart4Data.map(item => {
        item["Average Time"] = json.find(i => i.date === item.date)?.avgTime || 0;
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchAvgStatusTime = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/avg_status_time',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChart4Data(chart5Data.map(item => {
        item["In Progress"] = json.find(i => i.date === item.date)?.avgTime || 0;
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchAvgReplyTime = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/avg_reply_time',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChart5Data(chart5Data.map(item => {
        item['Escalated'] = json.find(i => i.date === item.date)?.escalated || 0;
        item['Awaiting Sync'] = json.find(i => i.date === item.date)?.awaiting_sync || 0;
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  const fetchAppointmentTypes = async () => {
    const accessToken = await getToken();
    const response = await fetch('/api/v1/reports/appointment_type',
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setChart6Data(chart6Data.map(item => {
        item['Email'] = json.find(i => i.date === item.date)?.email || 0;
        item['Phone'] = json.find(i => i.date === item.date)?.phone || 0;
        item['Portal'] = json.find(i => i.date === item.date)?.portal || 0;
        return item;
      }));
    } else {
      railsToast({ info: json.error }, status);
    }
  }

  if (loading) {
    return <></>;
  }

  return (
    <Stack gap={4} className="mb-2">
      <StatsGrid data={data} />
      <Row className="align-items-stretch">
        <Col sm={12} xl={6} className="d-flex flex-column justify-content-between">
          <div className="fw-bold ms-4 my-2">
            Daily Snapshot <span className="fw-normal fs-9">
              (# of tasks entering each status on a given day)</span>
          </div>
          <LineChart h={250} data={chartData} dataKey="date"
            series={[
              { name: 'Suggested', color: "orange.6" },
              { name: 'In Progress', color: "blue.6" },
              { name: 'Escalated', color: "red.6" },
              { name: 'Completed', color: "green.6" },
              { name: 'Closed Outside HubFlow', color: "green.3" },
            ]}
            withDots={false}
            withLegend />
        </Col>
        <Col sm={12} xl={6} className="d-flex flex-column justify-content-between">
          <div className="fw-bold ms-4 mt-2">
            Intervention Rate <span className="fw-normal fs-9">
              (% of tasks where human intervention was required)</span>
          </div>
          <LineChart h={250} data={chart2Data} dataKey="date"
            series={[
              { name: 'Contacts', color: "blue.6" },
              { name: 'Appointment Time', color: "red.6" },
              { name: 'Escalated', color: "green.6" },
            ]}
            withDots={false}
            withLegend />
        </Col>
        <Col sm={12} xl={6} className="d-flex flex-column justify-content-between">
          <div className="fw-bold ms-4 mt-2">
            Email Reading Accuracy <span className="fw-normal fs-9">(% of tasks)</span>
          </div>
          <LineChart h={250} data={chart3Data} dataKey="date"
            series={[
              { name: 'Confirmed Correctly', color: "green.6" },
              { name: 'Confirmed Differently', color: "red.6" },
            ]}
            withDots={false}
            withLegend />
        </Col>
        <Col sm={12} xl={6} className="d-flex flex-column justify-content-between">
          <div className="fw-bold ms-4 mt-2">
            Appointment Suggestion Accuracy <span className="fw-normal fs-9">
              (difference between suggested vs. proposed appointment times)</span>
          </div>
          <LineChart h={250} data={chart4Data} dataKey="date"
            series={[
              { name: 'Average Time', color: "red.6" },
            ]}
            withDots={false}
            withLegend />
        </Col>
        <Col sm={12} xl={6} className="d-flex flex-column justify-content-between">
          <div className="fw-bold ms-4 mt-2">
            Time to Schedule <span className="fw-normal fs-9">
              (average time spent in each status)</span>
          </div>
          <LineChart h={250} data={chart5Data} dataKey="date"
            series={[
              { name: 'In Progress', color: "orange.6" },
              { name: 'Escalated', color: "red.6" },
              { name: 'Awaiting Sync', color: "blue.6" },
            ]}
            withDots={false}
            withLegend />
        </Col>
        <Col sm={12} xl={6} className="d-flex flex-column justify-content-between">
          <div className="fw-bold ms-4 mt-2">
          Appointment Types <span className="fw-normal fs-9">
            (% of tasks with each scheduling method)</span>
          </div>
          <LineChart h={250} data={chart6Data} dataKey="date"
            series={[
              { name: 'Email', color: "orange.6" },
              { name: 'Phone', color: "blue.6" },
              { name: 'Portal', color: "green.6" },
            ]}
            withDots={false}
            withLegend />
        </Col>
      </Row>
    </Stack>
  );
};

export default Analytics;
