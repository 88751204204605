import { useEffect, useRef, useState } from "react";
import { Sop } from "../types";
import { Button, Form, Spinner, Stack } from "react-bootstrap";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";

const SopShow = () => {
  const { getToken } = useAuth();
  const railsToast = useRailsToast();
  const [sops, setSops] = useState<Sop[]>([]);
  const [sop, setSop] = useState<Sop>(null);
  const [instructions, setInstructions] = useState<string>('');
  const [systemPrompt, setSystemPrompt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [toRemove, setToRemove] = useState<number>(0);
  const instructionsRef = useRef<HTMLDivElement>(null);
  const [cursorPosition, setCursorPosition] = useState<Range>(null);
  const skippedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Shift', 'Home', 'End'];
  const [allowTest, setAllowTest] = useState<boolean>(false);

  const fetchSops = async () => {
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/sops`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      }
    });
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setSops([...json]);
    }
    setLoading(false);
  };

  const fetchSop = async (sopId) => {
    if (sop?.id !== sopId) {
      setLoading(true);
      const accessToken = await getToken();
      const response = await fetch(`/api/v1/sops/${sopId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      });
      const json = await response.json();
      const status = response.status;
      if ([200, 304].includes(status)) {
        setSop({...json});
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setAllowTest(!['app.gethubflow.ai', '216.24.57.4',
      'gethubflow-rails.onrender.com.cdn.cloudflare.net']
      .some(url => window.location.href.includes(url)));
    fetchSops();
  }, []);

  useEffect(() => {
    if (sop) {
      setInstructions(sop.instructions);
      setSystemPrompt(sop.systemPrompt);
    }
  }, [sop]);

  useEffect(() => {
    if (toRemove !== 0) {
      removeNearNode(window.getSelection().anchorNode, toRemove);
      setToRemove(0);
    }
  }, [toRemove]);

  useEffect(() => {
    if (loading)
      saveSop();
  }, [instructions, systemPrompt]);

  const test = async () => {
    const accessToken = await getToken();
    await fetch('/api/v1/test', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  const saveSop = async () => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/sops/${sop.id}`, {
      method: "PUT",
      body: JSON.stringify({ sop: { instructions: instructions, systemPrompt: systemPrompt } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setSop({...sop, instructions: instructions, systemPrompt: systemPrompt});
      railsToast({ info: "SOP changes has been saved!" }, status);
    }
    else
      railsToast(json, status);
    setLoading(false);
  }

  const saveChanges = () => {
    setLoading(true);
    const newInstructions = convertToText(instructionsRef.current.innerHTML);
    if (newInstructions !== instructions)
      setInstructions(newInstructions);
    else if (allowTest)
      saveSop();
    else
      setLoading(false);
  };

  const TrySetCursorPosition = (selection: Selection = window.getSelection()) => {
    if (instructionsRef.current === selection.anchorNode.parentElement &&
      instructionsRef.current === selection.focusNode.parentElement &&
      selection.anchorOffset === selection.focusOffset)
      setCursorPosition(selection.getRangeAt(0))
    else
      setCursorPosition(null);
  }

  const inputVar = (input: string) => {
    instructionsRef.current.focus();
    let range = cursorPosition;
    const selection = window.getSelection();
    if (!range)
      range = selection.getRangeAt(0);
    range.insertNode(document.createRange().createContextualFragment(
      `&nbsp<div class="bg-info-subtle text-info-emphasis px-2 py-1 rounded d-inline-block">${input}</div>&nbsp`));
    selection.removeAllRanges();
  }

  const highlightInputs = (text: string) => {
    let html = "";
    let lastIndex = 0;
    for (let match of text?.matchAll(/\{([a-zA-Z0-9_]+)\}/g)) {
      if (sop.input?.includes(match[1])) {
        html += text.substring(lastIndex, match.index);
        html += `<div class="bg-info-subtle text-info-emphasis px-2 py-1 rounded d-inline-block">${match[1]}</div>&nbsp`;
      }
      else
        html += text.substring(lastIndex, match.index + match[0].length);
      lastIndex = match.index + match[0].length;
    }
    html += text.substring(lastIndex);
    return html.replaceAll('\n', '<br>');
  };

  const highlightOutputs = (text: string) => {
    for (let match of text?.matchAll(/\{([a-zA-Z0-9_]+)\}/g)) {
      if (sop.output?.includes(match[1])) {
        return (
          <>
            {text.substring(0, match.index)}
            <div className="bg-warning-subtle text-warning-emphasis px-2 py-1 rounded d-inline-block">{match[1]}</div>&nbsp;
            {highlightOutputs(text.substring(match.index + match[0].length))}
          </>
        );
      }
    }
    return <>{text}</>;
  };
  
  const convertToText = (html: string) => {
    const regex = /<div class="bg-info-subtle text-info-emphasis px-2 py-1 rounded d-inline-block">([^<]+)<\/div>/;
    let match: RegExpExecArray | null;
    while ((match = regex.exec(html)) !== null) {
      html = `${html.substring(0, match.index)} {${match[1]}} ${html.substring(match.index + match[0].length)}`;
    }
    return html.replaceAll('&nbsp;', ' ').replaceAll(/\s+/g, ' ').replaceAll('<br>', '\n').replaceAll(/<[^>]*>/g, '');
  };

  const removeNearNode = (varNode: Node, offset: number) => {
    let index = 0;
    const nodes = Array.from(instructionsRef.current.childNodes);
    for (let node of nodes) {
      if (node === varNode) {
        nodes[index + offset].parentElement.removeChild(nodes[index + offset]);
        break;
      }
      index++;
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const selection = window.getSelection();
    if (selection) {
      if (selection.anchorNode.parentElement?.classList?.contains("bg-info-subtle") &&
        (instructionsRef.current !== selection.anchorNode.parentElement ||
        instructionsRef.current !== selection.focusNode.parentElement)) {
        if (!skippedKeys.includes(event.key))
          event.preventDefault();
        if (instructionsRef.current !== selection.anchorNode.parentElement
          && selection.focusNode === selection.anchorNode) {
          if (event.key === 'Backspace' || event.key === 'Delete')
            removeNearNode(selection.anchorNode.parentElement, 0);
        }
      }
      let nodes = Array.from(instructionsRef.current.childNodes);
      let index = nodes.indexOf(selection.anchorNode as ChildNode);
      if ((selection.anchorNode.parentElement !== selection.focusNode.parentElement &&
        (!skippedKeys.includes(event.key))) || (selection.anchorNode.parentElement == instructionsRef.current
        && ((event.key === 'Backspace' && selection.anchorOffset <= 1 && index > 0
          && (nodes[index - 1] as HTMLElement).classList?.contains("bg-info-subtle"))
        || (event.key === 'Delete' && (selection.anchorOffset >= selection.anchorNode.textContent.length - 1)
          && (nodes[index + 1] as HTMLElement).classList?.contains("bg-info-subtle"))))) {
        event.preventDefault();
        setToRemove(event.key === 'Backspace' ? -1 : (event.key === 'Delete' ? 1 : 0));
      }
    }
  };

  return (
    <>
      <Stack direction="horizontal" gap={2} className="mb-3">
        <span className="text-nowrap">Select SOP:</span>
        <Form.Select onChange={async (e) => await fetchSop(e.target.value)}>
          <option disabled={!!sop}>Select SOP</option>
          {sops.map(sop => <option value={sop.id}>{sop.name}</option>)}
        </Form.Select>
      </Stack>
      {allowTest ? <div><Button onClick={test}>Test</Button></div> : <></>}
      {loading ?
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> : <></>}
      {sop ? (
      <>
        <Stack direction="horizontal" className="justify-content-between">
          <h1 className="h2">{sop.name}</h1>
          <Button onClick={saveChanges} variant="outline-success">Save changes</Button>
        </Stack>
        <div className="border-bottom my-3"></div>
        <Stack direction="horizontal" className="align-items-start">
          <div className="w-50">
            <div className="my-3">Input variables</div>
            {sop.input?.map(v => (<div className="mt-2 d-flex">
              <div className="bg-info-subtle text-info-emphasis px-2 py-1 rounded"
                onClick={() => inputVar(v)} role="button">{v}</div></div>))}
          </div>
          <div className="w-50">
            <div className="my-3">Output variables</div>
            {sop.output?.map(v => (<div className="mt-2 d-flex">
              <div className="bg-warning-subtle text-warning-emphasis px-2 py-1 rounded">{v}</div></div>))}
          </div>
        </Stack>
        <div className="mt-3">Instructions</div>
        <div className="mt-2">
          <div contentEditable="true" ref={instructionsRef} className="form-control"
            dangerouslySetInnerHTML={{ __html: highlightInputs(instructions) }}
            onKeyDown={handleKeyDown} onKeyUp={() => TrySetCursorPosition()}
            onMouseUp={() => TrySetCursorPosition()}>
          </div>
        </div>
        <div className="mt-3">System prompt</div>
        {
          allowTest ? <Form.Control className="mt-2" value={systemPrompt}
          onChange={e => setSystemPrompt(e.target.value)} /> :
          <div className="mt-2 border rounded bg-body-tertiary p-2">
            {highlightOutputs(sop.systemPrompt)}
          </div>
        }
      </>) : <></>}
    </>
  );
};

export default SopShow;
