import moment from "moment-timezone";
import { Task } from "../tasks/types";

export const getScheduledDateTime = (task: Task, isDate: boolean) => {
  let value = task.scheduledAt.toLocaleString('sv', { timeZone: task.timezone,
    hour12: false, dateStyle: 'short', timeStyle: 'short'});
  if (isDate)
    value = value.split(' ')[0];
  else
    value = value.split(' ')[1];
  return value;
};

export const setScheduledDateTime = (task: Task, value: string, isDate: boolean) => {
  let fullValue = task.scheduledAt.toLocaleString('sv',
    { timeZone: task.timezone, hour12: false, dateStyle: 'short', timeStyle: 'short'})
  if (isDate)
    fullValue = `${value} ${fullValue.split(' ')[1]}`;
  else
    fullValue = `${fullValue.split(' ')[0]} ${value}`;
  return new Date(moment.tz(fullValue, task.timezone));
};

export const formatDateTime = (date: Date) =>
  date ? date.toLocaleString('en-us', { hour12: true, dateStyle: 'short', timeStyle: 'short'}) : null;

export const timezones = {
  "US/Eastern": "ET",
  "US/Central": "CT",
  "US/Mountain": "MT",
  "US/Pacific": "PT",
  "US/Alaska": "AKT",
  "US/Hawaii": "HT"
};

export const generateSubject = (task: Task) => {
  if (task.poNum?.length != 1) {
    return `Walmart Pickup Request / ID# ${task.shipment.bolNum } / PRO# ${task.shipment?.shipmentId}`;
  } else {
    return `Walmart Pickup Request / ID# ${task.shipment.bolNum } / PO# ${task.poNum[0]} / PRO# ${task.shipment.shipmentId}`
  }
}

export const getShortDateTime = (date: Date, timezone: string) => {
  return date.toLocaleString('sv', { timeZone: timezone,
      hour12: false, dateStyle: 'short', timeStyle: 'short'}).substring(5).replace('-', '/');
}

export const generateMessage = (task: Task) => {
  const scheduledAt = `${getShortDateTime(task.scheduledAt, task.timezone)} ${timezones[task.timezone]}`;
  if (task.poNum?.length > 1) {
    return `Hello,\n\nCould we schedule a pickup appointment for the below listed Walmart PO numbers for ${scheduledAt} or around that time?\n\nPO's:\n${task.poNum.join('\n')}\n\nThank you,`;
  } else {
    return `Hello,\n\nCould we schedule a pickup appointment for the above Walmart PO number for ${scheduledAt} or around that time?\n\nThank you,`
  }
}