import { ContactType } from "../tasks/types";

export const getContactType = (contact) => {
  if (contact.includes('@'))
    return ContactType.EMAIL;
  else if (contact.toLowerCase().startsWith('http'))
    return ContactType.PORTAL;
  else
    return ContactType.PHONE;
}

export const getContactIcon = (contactType) => {
  switch (contactType) {
    case ContactType.PORTAL:
      return "bi bi-link-45deg";
    case ContactType.PHONE:
      return "bi bi-telephone";
    default:
      return "bi bi-envelope-at";
  }
}