import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner, Stack, Table } from "react-bootstrap";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { Facility } from "../type";
import TaskStatusPill from "@/components/tasks/show/task-status-pill";
import ContactsInput from "@/components/tasks/show/contacts/contacts_input";

const FacilityShow = () => {
  const { getToken } = useAuth();
  const { facilityId } = useParams();
  const railsToast = useRailsToast();
  const [facility, setFacility] = useState<Facility>(null);
  const [loading, setLoading] = useState(true);

  const fetchFacility = async () => {
    const accessToken = await getToken();
    const response = await fetch(
      `/api/v1/facilities/${facilityId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setFacility({...json, tasks: json.tasks.map(task => ({...task, scheduledAt: new Date(task.scheduledAt) }))});
    } else {
      railsToast({ info: json.error }, status);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFacility();
  }, []);

  if (loading) {
    return (
    <div className="text-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    );
  }
  if (facility)
  return (
    <>
      <h1 className="h2">{facility.name}</h1>
      <div className="border-bottom my-3"></div>
      <div className="fw-bold">{facility.address}</div>
      <div className="fw-bold">{facility.city}, {facility.state}, {facility.zipCode}</div>
      <Row className="mt-4 mb-2">
        <Col xs={12} md={3} lg={2}>
          <Stack direction="horizontal"  className="align-items-start">
            <span className="fw-bold">Hours</span>
            <div className="ps-1">
              <div>{facility.hours}</div>
            </div>
          </Stack>
        </Col>
        <Col xs={12} md={9} lg={10}>
          <div className="fw-bold">Notes</div>
          <ul>
            {JSON.parse(facility.facilityNotes?.[0]?.notes || '[]').flat()
              .filter(note => note).map(note => (<li>{note}</li>))}
          </ul>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Appointments</th>
            <th>Date</th>
            <th>Contacts</th>
            <th>Scheduling Method</th>
            <th>Shipment</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {facility.tasks.map(task => (
            <tr className="align-middle">
              <td>
                <Stack direction="horizontal" gap={2} className="text-capitalize">
                  <a href={`/tasks/${task.id}`}>{task.stopType.replace('_', ' ')}</a>
                </Stack>
              </td>
              <td>{task.scheduledAt.toLocaleDateString('us', { timeZone: task.timezone })}</td>
              <td><ContactsInput task={task} disabled={true} showAll={false} /></td>
              <td className="text-capitalize">{task.schedulingMethod}</td>
              <td><a href={`/shipments/${task.shipmentId}`}>{task.shipment?.shipmentId}</a></td>
              <td><TaskStatusPill status={task.status} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export default FacilityShow;
