import { useOrganization } from "@clerk/clerk-react";
import { AGENT, CUSTOMER, UNASSIGNED, UserType } from "../users/types";

const useUserType = (): UserType => {
  const { organization } = useOrganization();

  if (!organization) {
    return UNASSIGNED;
  } else if (
    organization.id == import.meta.env.VITE_CLERK_AGENT_ORGANIZATION_ID
  ) {
    return AGENT;
  } else {
    return CUSTOMER;
  }
};

export default useUserType;
